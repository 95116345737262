@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('assets/fonts/stylesheet.css');
//@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
    margin: 0;
    font-family: 'Avenir Next Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: 15px;
    overflow-y: auto;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #fff;
}

body::-webkit-scrollbar {
    width: 1em;
}

#react-burger-menu-btn {
    width: 30px !important;
    height: 30px !important;
    top: 60px !important;
    left: 10px !important;
    background: url('./assets/icons/menu.png') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.bg-term {
    background-color: #FAFAFA;
}

.pointer {
    cursor: pointer;
}

.top-60 {
    top: 60px;
}

.w-20 {
    width: 20px;
}

a:hover {
    color: gray;
}

.left-20 {
    left: 20px;
}

.sidebar-mobile {
    transform: translateX(-150%);
    z-index: 1000;
    transition: 0.3s ease;
}

.button-sidebar {
    cursor: pointer;
}

.button-sidebar:focus+.sidebar-mobile {
    transform: translateX(0);
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.tooltip {
    opacity: 0;
}

.card-tooltip:hover+.tooltip {
    opacity: 1;

}
.bgLogin{
    background: url("https://cakeinvest-frontend.s3.amazonaws.com/BGLogin.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}
#bgMobile{
    background: url("https://cakeinvest-frontend.s3.amazonaws.com/bgmobile.png");
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

#noHover {
    pointer-events: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (min-width: 1609px) {
    .large-screen {
        margin-top: 25px;
    }
}