// @import url('assets/fonts/stylesheet.css');

*,
*:before,
*:after {
    box-sizing: border-box;
}
html,
body {
    margin: 0;
    padding: 0;
}
body {
    font-size: 16px;
    font-family: 'Avenir Next';
    line-height: 1.3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.absolute {
    position: absolute;
}
.fill {
    width: 100%;
    height: 100%;
}
.flex {
    display: flex;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.secret {
    font-size: 2.5em;
    font-weight: 700;
    border-radius: 6px;
    margin-left: 1px;
    background-color: #fafafa;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}